// Margins
:root {
  --px-0: 0px;
  --px-1: 1px;
  --px-2: 2px;
  --px-3: 3px;
  --px-4: 4px;
  --px-5: 5px;
  --px-6: 6px;
  --px-7: 7px;
  --px-8: 8px;
  --px-9: 9px;
  --px-10: 10px;
  --px-11: 11px;
  --px-12: 12px;
  --px-13: 13px;
  --px-14: 14px;
  --px-15: 15px;
  --px-16: 16px;
  --px-17: 17px;
  --px-18: 18px;
  --px-19: 19px;
  --px-20: 20px;
  --px-21: 21px;
  --px-22: 22px;
  --px-23: 23px;
  --px-24: 24px;
  --px-25: 25px;
  --px-26: 26px;
  --px-27: 27px;
  --px-28: 28px;
  --px-29: 29px;
  --px-30: 30px;
  --px-31: 31px;
  --px-32: 32px;
  --px-33: 33px;
  --px-34: 34px;
  --px-35: 35px;
  --px-36: 36px;
  --px-37: 37px;
  --px-38: 38px;
  --px-39: 39px;
  --px-40: 40px;

  /********** Home Page  **********/
  //Banner
  --banner-img-width: 19rem;
  // --banner-img-height: 9.25rem;
  --banner-img-height: 200px;
  --banner-img-radius: 0.75rem;

  //Full width ads
  --full-ads-img-width: 89vw;
  --full-ads-img-height: 55vw;
  --full-ads-img-radius: 0.625rem;

  //Top Brands
  --top-brand-img-width: 3.75rem;
  --top-brand-img-height: 3.75rem;
  --top-brand-card-width: 6.68rem;
  --top-brand-card-height: 7.93rem;
  --top-brand-card-radius: 0.68rem;

  //Popular cusisines
  --popular-cuisinus-img-width: 6.25rem;
  --popular-cuisinus-img-height: 7.93rem;
  --popular-cuisinus-img--radius: 0.5rem;

  //Multi ads
  --multi-ads-img-width: 7.75rem;
  --multi-ads-img-height: 8.81rem;
  --multi-ads-img-radius: 0.5rem;

  //Top restaurant
  --top-rest-img-width: 7rem;
  --top-rest-img-height: 5.25rem;
  --top-rest-img-radius: 0.68rem;

  //New launched
  --new-launch-img-width: 11rem;
  --new-launch-img-height: 5.62rem;
  --new-launch-img-radius: 0.68rem;
  --new-launch-card-width: 11rem;

  //Yumzy Exclusive
  --yumzy-exclu-img-bg-width: 12rem;
  --yumzy-exclu-img-bg-height: 8.87rem;
  --yumzy-exclu-img-bg-radius: 0.75rem;
  --yumzy-exclu-img-sm-width: 8.93rem;
  --yumzy-exclu-img-sm-height: 5.81rem;
  --yumzy-exclu-img-sm-radius: 0.5rem;

  --yumzy-exclu-card-bg-width: 11rem;
  --yumzy-exclu-card-bg-radius: 0.5rem;
  --yumzy-exclu-card-sm-width: 8rem;
  --yumzy-exclu-card-sm-radius: 0.5rem;

  //Trending restaurant
  --trending-rest-img-width: 4rem;
  --trending-rest-img-height: 4.375rem;
  --trending-rest-img-radius: 0.68rem;

  //Yumzy Best
  --yumzy-best-card-width: 15.75rem;
  --yumzy-best-card-height: 8.06rem;
  --yumzy-best-card-radius: 0.75rem;
  --yumzy-best-img-width: 7.06rem;
  --yumzy-best-img-height: 6.93rem;
  --yumzy-best-img-radius: 0.5rem;

  // Winter special
  --winter-special-img-width: 6rem;
  --winter-special-img-height: 5.8rem;
  --winter-special-img-radius: 0.5rem;

  //Popular food
  --popular-food-card-width: 15.75rem;
  --popular-food-card-height: 8.06rem;
  --popular-food-card-radius: 0.5rem;
  --popular-food-img-width: 7rem;
  --popular-food-img-height: 8rem;
  --popular-food-img-radius: 0.5rem;

  // Trending Food
  --trending-food-img-width: 4rem;
  --trending-food-img-height: 4.375rem;
  --trending-food-img-radius: 0.5rem;
}
