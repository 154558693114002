// for filter menu by category modal
.catModal {
  overflow: none !important;
  animation: animate-bottom 0.5s;
  position: relative;
  .modal {
    overflow: none;
  }
  .modal-dialog {
    max-height: 75% !important;
    margin: 0;
    .modal-content {
      bottom: -18px !important;
      position: absolute !important;
      max-height: 27.5rem;
      overflow-y: auto !important;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
}

@keyframes animate-bottom {
  from {
    transform : translateY(100%);
    opacity: 0;
  }
  to { 
    transform : translateY(0%);
    opacity: 1;
  }
}

.rateModal {
  .modal-content {
    border-radius: 10px;
  }
}

.modal-animation {
  animation-name: example;
  animation-duration: 0.5s;
  transition: 0.25s all;
}
@keyframes example {
  0% {
    transform: scale(0.5);
  }
  // 75% {
  //   transform: scale(1.1);
  // }
  100% {
    transform: scale(1);
  }
}

@keyframes animate-close {
  from { 
    transform : translateY(0%);
  }
  to { 
    transform : translateY(90%);
  }
}

.modal-close {
  @extend .addon-variant-modal-css;
  -webkit-animation: animate-close 0.4s;
  animation: animate-close 0.4s;
}

// for order placed modal
.order-place-modal > .modal-dialog {
  height: 50%;
  .modal-content {
    margin: 0px 28px;
    height: 50%;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25),
      -5px -5px 19px -11px rgba(0, 0, 0, 0.22);
    @extend .modal-animation;
  }
}

.addon-variant-modal-css > .modal-dialog {
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    max-height: 90% !important;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.rating-modal-mobile-css > .modal-dialog {
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    max-height: 100% !important;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0px !important;
  }
}

.confirm-modal-type2-css > .modal-dialog {
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    max-height: 90% !important;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 20px !important;
  }
}

.no-internet-modal-css > .modal-dialog {
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    height: 1000% !important;
    border: 0px;
  }
}

.change-address-modal-css > .modal-dialog {
  margin: 0;
  margin: 20px 0px 0px 0px;
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    // min-height: 70vh !important;
    max-height: 70vh !important;
    border-top-left-radius: 0.75em;
    border-top-right-radius: 0.75em;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: 0;
  }
}
.change-address-modal-css {
  overflow: hidden !important;
}

.tax-and-charges-modal > .modal-dialog {
  margin: 0;
  margin: 20px 0px 0px 0px;
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    min-height: auto !important;
    height: auto;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.confirmation-modal-css > .modal-dialog {
  .modal-content {
    margin: 0px 10px;
    border: 0;
    border-radius: 0.625rem;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25),
      -5px -5px 19px -11px rgba(0, 0, 0, 0.22);
    @extend .modal-animation;
  }
}
.center-confirmation-modal-css {
  @extend .confirmation-modal-css;
  > .modal-dialog {
    max-width: 80%;
    margin: auto;
    .modal-content{
      margin: 0px;
    }
  }
}
.rating-modal-mobile-css > .modal-dialog {
  margin: 0 auto !important;
  .modal-content {
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    // margin: 0px 10px;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25),
      -5px -5px 19px -11px rgba(0, 0, 0, 0.22);
    @extend .modal-animation;
  }
}

.rating-modal-mobile-css > .modal-dialog-centered {
  height: 100% !important;
}

.thanks-for-rating-modal > .modal-dialog {
  .modal-content {
    width: 280px;
    margin: 0px auto;
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25),
      -5px -5px 19px -11px rgba(0, 0, 0, 0.22);
    @extend .modal-animation;
  }
}

.coupon-confirmation-modal > .modal-dialog {
  .modal-content {
    width: 280px;
    margin: 0px auto;
    border: 0;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25),
      -5px -5px 19px -11px rgba(0, 0, 0, 0.22);
    @extend .modal-animation;
  }
}

.scratch-modal-css-common {
  height: 100% !important;
  margin: unset !important;
  // transition: transform 0.5s ease-out !important;
  // --webkit-transform: 0.5s ease-out !important;
  max-width: 100% !important;

  transform: scale(0.01);
  animation: unfoldIn 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.scratch-card-order-track > .modal-dialog {
  background: #fff;
  box-shadow: 0 0 3px #a1a1a1;

  .modal-content {
    // transform: scale(0);
    // animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    background: none !important;
    border: none !important;
  }
  @extend .scratch-modal-css-common;
}

.scratch-card-earned > .modal-dialog {
  background: #0000008a;

  .modal-content {
    // animation-name: example;
    // animation-duration: 0.2s;
    background: none !important;
    border: none !important;
  }
  @extend .scratch-modal-css-common;
}

@keyframes unfoldIn {
  0% {
    transform: scale(0.005);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width //
  .modal.left .modal-dialog {
    width: 100% !important;
  }
  .modal.right .modal-dialog {
    width: 100% !important;
  }
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0) !important;
  -ms-transform: translate3d(0%, 0, 0) !important;
  -o-transform: translate3d(0%, 0, 0) !important;
  transform: translate3d(0%, 0, 0) !important;
}
.bottom-modal > .modal-dialog {
  margin: 20px 0px 0px 0px;
  width: 100%;
  .modal-content {
    -webkit-animation: animate-bottom 0.5s;
    animation: animate-bottom 0.5s;
    bottom: 0;
    position: fixed !important;
    max-height: 90% !important;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.bottom-modal {
  height: auto !important;
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
  border: none;
  padding-top: 1rem;
}

.order-track-modal .modal-content {
  overflow-y: hidden !important;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
  padding: 15px 15px 80px;
}
.come-from-modal.right.fade .modal-dialog {
  // right: -320px;
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important;
}

.come-from-modal.right.fade.in .modal-dialog {
  right: 0;
}

.hide-scroll-view .modal-content {
  height: 100%;
  overflow-y: hidden !important;
}

.header-view-modal {
  position: fixed;
  width: 100%;
  background: var(--white);
  z-index: 2000;
  border-bottom: 1px solid var(--mobile-top-header-btm-clr);
  top: 0;
  padding-top: 2rem;
  height: 5rem;
}
.closeBtn {
  float: right;
  cursor: pointer;
}

.categoryModal {
  overflow-y: auto !important;
  .modal {
    overflow-y: auto;
  }
  .modal-dialog {
    width: 80%;
    margin: auto;
    .modal-content {
      // padding: 1rem;
      border-radius: 0.625rem;
      bottom: 3.5rem;
      max-height: 400px !important;
      overflow-y: auto;
      @extend .modal-animation;
      position: absolute !important;
    }
  }
}
@media screen and (min-height: 800px) {
  .categoryModal {
    .modal-content {
      bottom: 6.5rem;
    }
  }
}

.clearPlateModal {
  overflow: none !important;
  .modal {
    overflow: none;
  }
  .modal-dialog {
    .modal-content {
      padding: 2rem 1rem 2rem 1rem;
      border-radius: 2rem;
    }
  }
}
