p {
  margin: 0 !important;
}

.heading-hover:hover {
  color: var(--main-color);
}

.hover-pink-clr:hover {
  color: var(--hover-pink-clr);
}
.scroll-menu-page {
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  top: 1rem;
}

.form-input-group {
  input:focus {
    border: 1px solid #000;
  }
}
.activeFilter {
  background-color: var(--main-color);
  cursor: pointer;
  padding: 0.5rem;
  color: var(--white);
}

//   custom checkbox
/* The checkboxContainer */
.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--white);
  border: 1px solid #707070 !important;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: var(--white);
}

/* When th
e checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: var(--pink);
  border: none !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pointer {
  cursor: pointer;
}

.header {
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 1000;
  height: auto;
  // border-bottom: 1px solid var(--mobile-top-header-btm-clr);
  // padding: 1.5rem 0rem 0rem 0rem !important;
  // display: block;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // background-color: var(--white);
  // z-index: 1000;
  // height: 5rem;
  // padding-top: 2rem !important;
  // border-bottom: 1px solid var(--mobile-top-header-btm-clr);
}

// Square inside circle
.square {
  border: 1px solid;
  height: 10px;
  width: 10px;
  position: relative;

  .inner-circle {
    position: absolute;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    top: 50%;
    left: 50%;
    margin: -2.5px 0px 0px -2.5px;
  }
}
// Input error border color
.red-input-bdr {
  border: 1px solid var(--inp-error-red-border-clr) !important;
}

//Input error message text color
.error-view {
  height: 16px;
  .error-msg-txt {
    color: var(--inp-error-txt-clr);
    padding-left: 0.8rem;
  }
}

//Input css
.form-group {
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .form-control {
    width: 100%;
    border: 1px solid var(--inp-border-clr);
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    color: var(--inp-txt-clr);
  }

  ion-input {
    padding: 0.375rem 0.75rem !important;
  }

  input::-webkit-input-placeholder {
    color: var(--inp-placeholder-txt-clr);
    font-size: 14px;
  }

  input:-ms-input-placeholder {
    color: var(--inp-placeholder-txt-clr);
    font-size: 14px;
  }

  input::-ms-input-placeholder {
    color: var(--inp-placeholder-txt-clr);
    font-size: 14px;
  }

  input::-moz-placeholder {
    color: var(--inp-placeholder-txt-clr);
    font-size: 14px;
  }

  input::placeholder {
    color: var(--inp-placeholder-txt-clr);
    font-size: 14px;
  }
}

.collapsible-header {
  .header {
    height: 220px;
    background-color: var(--collapsible-hdr-bg-clr);
    z-index: unset;

    .left-arrow {
      background: none;
      border: none;
      outline: none;
    }

    .header-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: var(--px-20);
      text-align: center;
      color: var(--white);
      padding: 0 3rem;
    }

    .header-title {
      display: none;
      color: var(--white);
      font-weight: 700;
      span {
        padding: 0 1rem;
      }
    }
  }
  .header.fixed {
    z-index: 1000;
    height: 3.5rem;

    .header-content {
      display: none;
    }

    .header-title {
      display: contents !important;
    }
  }
}

.bottom-btn-view {
  position: fixed !important;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px;
  background: white;
  z-index: 200;
  padding-bottom: 20px;
}

.address-radio-group {
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: relative;
    margin-right: 1em;
    padding-left: 1.3em;
    padding-right: 1.3em;
    line-height: 2.2;
    cursor: pointer;
    background: #eff2ef;
    border-radius: 1em;
    color: #262730;
    font-size: 14px;
    border: 1px solid var(--primary-clr) !important;
  }
  label:before {
    box-sizing: border-box;
    content: " ";
    position: absolute;
    top: 0.4em;
    left: 0;
    display: block;
    width: 1.2em;
    height: 1.2em;
    // border: 1px solid #fe5069;
    border-radius: 0.25em;
    // z-index: -1;
  }

  input[type="radio"] + label::before {
    border-radius: 1em;
  }

  /* Checked */
  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {
    // padding-left: 1em;
    // color: #fe5069;
    color: var(--white);
    background: var(--primary-clr);
    border-radius: 1em;
    font-size: 14px;
  }
  input[type="checkbox"]:checked + label:before,
  input[type="radio"]:checked + label:before {
    top: 0;
    width: 100%;
    height: 2em;
    // background: #fe5069;
  }

  /* Transition */
  label,
  label::before {
    -webkit-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
}

// Home title view css
.home-title-view {
  .title-txt {
    color: var(--home-layout-title-clr);
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.5;
  }
  .dec-txt {
    color: var(--home-layout-des-clr);
    font-size: 0.625em;
    font-weight: 400;
  }
}

.see-all-txt {
  color: var(--see-all-rest-txt-clr);
  font-size: 0.75rem;
  font-weight: 400;
  span {
    margin-left: 0.25rem;
  }
}

.img-shadow {
  -moz-box-shadow: 0px 0px 3px #ccc;
  -webkit-box-shadow: 0px 0px 3px #ccc;
  box-shadow: 0px 0px 3px #ccc;
}

.limit-line-length-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit-line-length-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.limit-line-length-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.op-50 {
  opacity: 50%;
}

.line-loader {
  position: absolute;
  bottom: 0px;
  width: 25%;
  border: 1px solid var(--primary-cta);
  z-index: 1;
  display: none;
  animation: getWidth 0.7s ease-in infinite !important;
}
@keyframes getWidth {
  0% {
    left: 0% ;
  }
  50% {
    left: 65% ;
  }
  100% {
    left: 0%;
  }
}

.image-page-center-view {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30em;
  height: 18em;
  margin-top: -9em;
  margin-left: -15em;
}

.dot-view {
  height: 2px;
  width: 2px;
  background-color: var(--time-and-amount-clr);
  display: inline-block;
  border-radius: 2px;
  vertical-align: middle;
  margin: 0 4px;
  margin-bottom: 3px;
}

#sc-container {
  max-width: 220px;
  width: 100%;
  height: 260px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
    border-radius: 9px;
  }
  .cardamountcss {
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: #000;
    font-size: 0.875rem;
    // font-weight: 600;
    line-height: 1.6;

    h3 {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      font-size: 1rem; // 18px
      font-weight: 600 !important;
    }

    // .won-amnt {
    //   font-size: 5em;
    //   color: #424242;
    // }
    // .won-text {
    //   font-size: 4em;
    //   color: #424242;
    // }
  }

  .background {
    // background: url("../scratch-cards/icons-on-card/background.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
  }

  .sc__inner {
    background-color: #ffffff;
    height: 99%;
    width: 98%;
    border-radius: 9px;
  }
}

ion-content {
  height: 100vh;
  overflow: auto;
  --padding-bottom:80px;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px;
  position: fixed !important;
  z-index: 3000 !important;
  left: 50%;
  bottom: 30px;
  font-size: 13px;
  transform: translateX(-50%);
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.swiper-slide,
.swiper-zoom-container {
  text-align: left !important;
}

body.modal-open {
  overflow: hidden;
}

.pac-container {
  z-index: 1100 !important;
  display: block;
}

/*style the box*/
.gm-style .gm-style-iw {
  top: 0 !important;
  left: 0 !important;
  width: 135pt !important;
  height: 100% !important;
  min-height: 50pt !important;
  display: block !important;
  border: none !important;
  font-size: 12px !important;
  text-align: center !important;
}

.gm-ui-hover-effect {
  display: none !important;
}
/*style the p tag*/
.gm-style .gm-style-iw #google-popup p {
  padding: 10px;
}
@media screen and (min-height: 800px) {
  .ios .footer-ios {
    padding-bottom: 2rem !important;
  }
}
@media screen and (min-height: 800px) {
  .header-ios {
    padding-top: 2.5rem !important;
  }
}
@media screen and (max-height: 790px) {
  .header-ios {
    padding-top: 1.5rem !important;
  }
}

/*style the arrow*/
// .gm-style div div div div div div div div {
//     padding: 0;
//     margin: 0;
//     padding: 0;
//     top: 0;
//     font-size: 16px;
// }
