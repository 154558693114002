:root {
  //Start New Color code
  --salmon-pink: #fc6f7f;
  --coral-pink: #ff595e;
  --pinkish: #e06c79;
  --warm-pink: #fa5f6e;
  --rosy-pink: #f06070;
  --red-pink: #f13d5b;
  --faded-red: #d94c5c;
  --cool-grey: #919299;
  --slate-grey: #68686f;
  --alto-grey: #d9d9d9;
  --nurse-grey: #eff2ef;
  --pale-grey: #f7f9fc;
  --greyish-50: #80b8b8b8;
  --light-grey: #eff2ef;
  --grey-2: #f1f1f1;
  --grey-1: #edf1f9;
  --white: #ffffff;
  --weird-green: #119839;
  --emerald-green: #54d97c;
  --spruce-green: #10623f;
  --greenish: #44af69;
  --dodger-blue: #3185fc;
  --light-royal-blue: #3d51ff;
  --iris-blue: #5158bb;
  --aqua-blue: #98c1d9;
  --dark-black: #262730;
  --cod-black: #1b1b1b;
  --shk-black: #202023;
  --light-gold: #ffd046;
  --black: #000000;

  --cuisines-bg-clr: hsla(214, 20%, 16%, 0.58);

  --primary-cta: #cc1e56;
  --layout-bg-color: #f1eff3;
  --input-border-clr: #e6e6e6;
  --primary-grey-2: #757575;
  --black-65: #00000065;
  --robin-blue-clr: #3483f4;
  --grey-input: #ddddde;
  --new-error-color: #dd2d4a;
  --primary-color-logo: #f67280;
  --bg-btn-clr: #fff4f5;
  --search-bar-clr: #f6f6f6;
  --login-option-clr: #f36d62;
  --login-btn-clr: #0e202a;

  // End new color code

  --gray: #282c3f;
  --light-gray: #d4d5d9;
  --light-white: #f9faf9;
  --pink: #ce255c;
  --white: #fff;
  --grey: #f7fafc;
  --light-yellow: #ffd046;
  --yellow-orange-mix: #ffca55;
  --coral: #fe5b60;
  --light-coral: #ff8754;
  --light-blue: #5487ff;
  --light-purple: #8c54ff;
  --light-green: #2fd66b;
  --light-brown: #783c23;
  --light-pink: #fe5069;
  // --light-black:#3d4152;
  --light-black: #171a29;
  --yellow: #fcd757;
  --gray92: #ebebeb;
  --suva-grey: #8f8f8f;
  --mantis: #53cc8e;
  --alice-blue: #f5f7fa;
  --thunderbird: #2d3597;
  --regent-st-blue: #4d98c1d9;
  --wild-watermelon: #fc6576;
  --tint-grey: #7a7a7a;
  --silver: #cccccc;
  --havelock-blue: #4a78d3;
  --bright-green: #3ddc97;
  --dim-gray: #707070;
  --primary-grey-clr: #f9f9f9;
  --old-rose: #cc1e56;
  --primary-clr: #3e2743;
  // --primary-cta: #cc1e56;
  --layout-bg-color: #f1eff3;
  --action-clr-green: #119839;
  --food-prepare-clr: #ff8d2c;
  --veg-clr: #28590c;
  --nonveg-clr: #cf231d;
  --egg-clr: #ffa631;
  --check-box-clr: #f67280;
  --box-shadow: #00000029;
  --scratch-banner-clr: #fc8005;

  //common color variable
  --heading-clr: var(--gray);
  --border-clr: var(--light-gray);
  --main-color: var(--pink);
  --white-color: var(--white);
  --text-color: var(--light-black);
  --hover-pink-clr: var(--coral-pink);
  --mobile-top-header-btm-clr: var(--alto-grey);
  --current-loc-txt: var(--rosy-pink);
  --collapsible-hdr-bg-clr: var(--thunderbird);

  --rest-clr: var(--dark-black);
  --menu-item-clr: var(--shk-black);
  --cuisine-clr: var(--cool-grey);
  --duration-clr: var(--suva-grey);
  --offer-clr: var(--pinkish);
  --rest-grey-clr: var(--cool-grey);
  --locality-clr: var(--dark-black);
  --cat-clr: var(--dark-black);
  --amt-clr: var(--dark-black);
  --sub-cat-clr: var(--cool-grey);
  --menu-dec-clr: var(--cool-grey);
  --customize-txt-clr: var(--cool-grey);

  //Home
  --home-layout-title-clr: var(--dark-black);
  --home-layout-des-clr: var(--cool-grey);
  --top-brand-bg-clr: var(--grey-1);

  --delivery-time-txt-clr: var(--cool-grey);
  --restaurant-clr: var(--shk-black);
  --rest-title-clr: var(--dark-black);
  --rest-des-clr: var(--cool-grey);
  --item-txt-clr: var(--dark-black);
  --item-amt-clr: var(--dark-black);
  --see-all-rest-txt-clr: var(--dodger-blue);
  --see-all-rest-bg-clr: var(--primary-cta);
  --rest-yellow-rating-bg-clr: var(--yellow);
  --duration-clr: var(--suva-grey);
  --track-btn-clr: var(--salmon-pink);

  //Input field related color class
  --inp-bg-clr: var(--nurse-grey);
  --inp-border-clr: var(--alto-grey);
  --inp-txt-clr: var(--dark-black);
  --inp-placeholder-txt-clr: var(--cool-grey);
  --inp-error-red-border-clr: var(--coral-pink);
  --inp-error-txt-clr: var(--coral-pink);
  --inp-check-box-clr: var(--check-box-clr);

  //Bottom nav bar
  --btm-nav-itm-clr: var(--cool-grey);
  --btm-nav-active-itm-clr: var(--salmon-pink);

  //Account page
  --adr-list-bg-clr: var(--pale-grey);
  --profile-upload-plus-icon-bg-clr: var(--coral-pink);
  --food-prefer-filter-bg-clr: var(--light-grey);

  //My Reward Page
  --my-reward-earning-badge-bg-clr: var(--salmon-pink);
  --total-earn-points-clr: var(--iris-blue);
  --total-earn-card-border-clr: var(--alto-grey);
  --point-card-bg-clr: var(--grey-2);

  //button
  --btn-red-bg-clr: var(--salmon-pink);
  --btn-green-bg-clr: var(--weird-green);
  --btn-red-txt-clr: var(--white);
  --btn-red-border-txt-clr: var(--salmon-pink);
  --btn-red-border-clr: var(--salmon-pink);
  --btn--trans-black-txt-clr: var(--dark-black);
  --btn--trans-red-txt-clr: var(--salmon-pink);
  --btn-green-txt-clr: var(--white);
  --btn-green-border-clr: var(--weird-green);
  --btn-red-rose-clr: var(--old-rose);

  //Offers page
  --coupon-title-clr: var(--dark-black);
  --coupon-bg-clr: var(--salmon-pink);
  --coupon-code-clr: var(--yellow);
  --border-clr: var(--gray92);
  --rewards-bg-color: var(--thunderbird);

  //explore page
  --search-bar-border-clr: var(--cool-grey);
  --search-bar-bg-clr: var(--light-grey);
  --selected-list-clr: var(--salmon-pink);
  --restaurant-name-clr: var(--shk-black);
  --discount-clr: var(--pinkish);
  --time-and-amount-clr: var(--suva-grey);
  --delivery-fee-clr: var(--mantis);
  --top-brand-bg-clr: var(--alice-blue);
  --dishes-border-clr: var(--alto-grey);
  --dishes-add-btn-clr: var(--weird-green);
  --offer-bg-clr: var(--wild-watermelon);

  //Menu and restaurant
  --rest-txt-clr: var(--shk-black);
  --offer-txt-clr: var(--pinkish);
  --add-btn-clr: var(--weird-green);
  --veg-text-clr: var(--bright-green);
  --closed-tag-bg-clr: var(--salmon-pink);
  --search-icon-border-clr: var(--dim-gray);

  //Order history page
  --odr-card-bg-clr: var(--pale-grey);
  --odr-outlet-name-txt-clr: var(--dark-black);
  --odr-order-num-txt-clr: var(--dark-black);
  --odr-menu-item-txt-clr: var(--cool-grey);
  --odr-time-txt-clr: var(--cool-grey);
  --odr-amt-txt-clr: var(--dark-black);
  --odr-cancel-txt-clr: var(--rosy-pink);
  --odr-bill-details-card-bg-clr: var(--pale-grey);
  --odr-price-clr: var(--cool-grey);
  --odr-discount-clr: var(--light-green);
  --odr-time-clr: var(--cool-grey);
  --odr-complete-txt-clr: var(--light-green);
  --odr-processing-txt-clr: var(--light-yellow);

  // Order track page
  --items-list-clr: var(--tint-grey);
  --arriving-bg-clr: var(--havelock-blue);
  --delivery-partner-clr: var(--dodger-blue);
  --phone-icon-bg-clr: var(--light-green);

  //Rating page
  --rating-like-most-bdr-clr: var(--salmon-pink);
  --rating-msg-active-bg-clr: var(--weird-green);
  --rating-quotation-bg-clr: var(--pale-grey);

  //OTP page
  --change-number-txt-clr: var(--dodger-blue);
  --resend-otp-btn-clr: var(--dodger-blue);
  --timer-txt-clr: var(--salmon-pink);

  // Your plate page
  --btm-title-txt-clr: var(--dark-black);
  --btm-change-txt-clr: var(--salmon-pink);
  --btm-des-txt-clr: var(--cool-grey);
  --billing-view-bg-clr: var(--pale-grey);
  --discount-txt-clr: var(--weird-green);
  --customizable-txt-clr: var(--salmon-pink);
  --change-adr-txt-clr: var(--light-blue);
  --add-new-adr-txt-clr: var(--salmon-pink);
}

// Menu and restaurant color class
.rest-txt-clr {
  color: var(--shk-black);
}
.cuisine-txt-clr {
  color: var(--cool-grey);
}
.offer-txt-clr {
  color: var(--pinkish);
}
.rating-txt-clr {
  color: var(--dark-black);
}

//we are using below color class for globally direct in html
.coral-pink-clr {
  color: var(--coral-pink);
}
.red-pink-clr {
  color: var(--red-pink);
}
.rosy-pink-clr {
  color: var(--rosy-pink);
}

.food-prepare-clr {
  color: var(--food-prepare-clr);
}

.action-clr-green {
  color: var(--action-clr-green);
}

.robin-blue-clr {
  color: var(--robin-blue-clr);
}

.veg-clr {
  color: var(--veg-clr);
}
.nonveg-clr {
  color: var(--nonveg-clr);
}
.egg-clr {
  color: var(--egg-clr);
}

.silver {
  color: var(--silver);
}

.primary-grey-clr {
  color: var(--primary-grey-clr);
}
.primary-clr {
  color: var(--primary-clr);
}

.primary-grey-2 {
  color: var(--primary-grey-2);
}
.primary-cta {
  color: var(--primary-cta);
}
.new-error-color {
  color: var(--new-error-color);
}

.salmon-pink-clr {
  color: var(--salmon-pink);
}

.shk-black-clr {
  color: var(--shk-black);
}
.cod-black-clr {
  color: var(--cod-black);
}

.dark-black-clr {
  color: var(--dark-black);
}
.primary-color-logo {
  color: var(--primary-color-logo);
}

.white-clr {
  color: var(--white);
}
.weird-green {
  color: var(--weird-green) !important;
}
.debit-entry-clr {
  color: #e02828;
}

.cool-grey-clr {
  color: var(--cool-grey);
}

.red-pink-clr {
  color: var(--red-pink);
}

.dodger-blue-clr {
  color: var(--dodger-blue) !important;
}

.red-rose-clr {
  color: var(--old-rose);
}
.black-clr {
  color: var(--black);
}
