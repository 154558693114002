.yum-btn {
  color: var(--btn-red-txt-clr) !important;
  border-radius: var(--px-6) !important;
  outline: none;
  font-size: 14px !important;
}
.btn-pd {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.btn-red-bg-sm {
  background: var(--btn-red-bg-clr) !important;
  @extend .yum-btn;
}

.btn-red-bg {
  background: var(--btn-red-bg-clr) !important;
  font-weight: 600 !important;
  font-size: 1em !important;
  @extend .yum-btn;
  @extend .btn-pd;
}

.btn-grey-bg {
  background: var(--layout-bg-color) !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: var(--primary-grey-2) !important;
  @extend .yum-btn;
  @extend .btn-pd;
}

.btn-green-bg {
  background: var(--btn-green-bg-clr) !important;
  @extend .btn-pd;
  @extend .yum-btn;
}

.btn-red-bdr {
  color: var(--btn-red-border-txt-clr) !important;
  border-radius: var(--px-6) !important;
  border: 1px solid var(--btn-red-border-clr) !important;
  outline: none !important;
}

.btn-green-bg {
  background: var(--btn-green-bg-clr) !important;
  color: var(--btn-green-txt-clr) !important;
  border-radius: var(--px-6) !important;
}

.btn-transparent {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none !important;
  overflow: hidden;
  outline: none !important;
}

.btn-pink-bg {
  background: var(--primary-cta) !important;
  font-weight: 600 !important;
  font-size: 1em !important;
  @extend .yum-btn;
  @extend .btn-pd;
}

.btn-fw-br-pink {
  color: var(--primary-cta) !important;
  border-radius: var(--px-6) !important;
  border: 1px solid var(--primary-cta) !important;
  outline: none !important;
}

.btn-red-transparent {
  @extend .btn-transparent;
  color: var(--btn--trans-red-txt-clr);
}

.btn-black-transparent {
  @extend .btn-transparent;
  color: var(--btn-black-txt-clr);
}

.item-add-btn {
  outline: none !important;
  color: var(--white) !important;
  letter-spacing: 1px !important;
  background: var(--add-btn-clr) !important;
  height: 28px !important;
  width: 80px !important;
  line-height: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.with-qty-view {
  float: right !important;
  display: inline-flex;
  .qry-count {
    color: #262730;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    margin-top: 3px;
    width: 24px;
  }
  .btn-plus {
    background: var(--btn-green-bg-clr);
    color: var(--white);
    height: 28px;
    width: 28px;
    font-size: 24px;
    padding: 6px;
    line-height: 12px;
  }
  .btn-minus {
    color: var(--btn-green-border-clr);
    border: 1px solid var(--btn-green-border-clr);
    height: 28px;
    width: 28px;
    padding: 6px;
    font-size: 24px;
    line-height: 12px;
  }
}
.btn-primary-cta {
  background: var(--btn-red-rose-clr) !important;
  border-radius: 4px;
  color: var(--white) !important;
  padding: 10px !important;
  outline: none;
  letter-spacing: 1;
}
