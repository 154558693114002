
/** Regular */
@font-face {
    font-family: "Poppins-Light";
    font-weight: 300;
    src: url("../font/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    font-weight: 400;
    src: url("../font/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    font-weight: 500;
    src: url("../font/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    font-weight: 600;
    src: url("../font/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    font-weight: 700;
    src: url("../font/Poppins-Bold.ttf");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    font-weight: 800;
    src: url("../font/Poppins-ExtraBold.ttf");
}

.sitefontlight {
  font-family: "Poppins-Light" !important;
}

.sitefontnormal {
  font-family: "Poppins-Regular" !important;
}

.sitefontmedium {
  font-family: "Poppins-Medium" !important;
}

.sitefontbold {
  font-family: "Poppins-SemiBold" !important;
}

.sitefontextrabold {
  font-family: "Poppins-Bold" !important;
}


