.fs-8 {
  font-size: var(--px-8) !important;
}
.fs-9 {
  font-size: var(--px-9) !important;
}
.fs-10 {
  font-size: var(--px-10) !important;
}
.fs-11 {
  font-size: var(--px-11) !important;
}
.fs-12 {
  font-size: var(--px-12) !important;
}
.fs-13 {
  font-size: var(--px-13) !important;
}
.fs-14 {
  font-size: var(--px-14) !important;
}
.fs-15 {
  font-size: var(--px-15) !important;
}
.fs-16 {
  font-size: var(--px-16) !important;
}
.fs-17 {
  font-size: var(--px-17) !important;
}
.fs-18 {
  font-size: var(--px-18) !important;
}
.fs-19 {
  font-size: var(--px-19) !important;
}
.fs-20 {
  font-size: var(--px-20) !important;
}
.fs-21 {
  font-size: var(--px-21) !important;
}

.fs-22 {
  font-size: var(--px-22) !important;
}

.fs-23 {
  font-size: var(--px-23) !important;
}

.fs-24 {
  font-size: var(--px-24) !important;
}

.fs-25 {
  font-size: var(--px-25) !important;
}

.fs-26 {
  font-size: var(--px-26) !important;
}

.fs-27 {
  font-size: var(--px-26) !important;
}

.fs-28 {
  font-size: var(--px-27) !important;
}

.fs-29 {
  font-size: var(--px-28) !important;
}

.fs-30 {
  font-size: var(--px-30) !important;
}

.fs-31 {
  font-size: var(--px-31) !important;
}
.fs-32 {
  font-size: var(--px-32) !important;
}
.fs-33 {
  font-size: var(--px-33) !important;
}
.fs-34 {
  font-size: var(--px-34) !important;
}
.fs-35 {
  font-size: var(--px-35) !important;
}
.fs-36 {
  font-size: var(--px-36) !important;
}
.fs-37 {
  font-size: var(--px-37) !important;
}
.fs-38 {
  font-size: var(--px-38) !important;
}
.fs-39 {
  font-size: var(--px-39) !important;
}
.fs-40 {
  font-size: var(--px-40) !important;
}

// font weight

.fw-2 {
  font-weight: 200 !important;
}
.fw-4 {
  font-weight: 400 !important;
}

.fw-5 {
  font-weight: 500 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.fw-7 {
  font-weight: 700 !important;
}

.fw-8 {
  font-weight: 800 !important;
}

// opacity
.op-1 {
  opacity: 0.1;
}
.op-2 {
  opacity: 0.2;
}
.op-3 {
  opacity: 0.3;
}
.op-4 {
  opacity: 0.4;
}
.op-5 {
  opacity: 0.5;
}
.op-6 {
  opacity: 0.6;
}
.op-7 {
  opacity: 0.7;
}
.op-8 {
  opacity: 0.8;
}
.op-9 {
  opacity: 0.9;
}
