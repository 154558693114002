@font-face {
  font-family: 'icomoon';
  src:  url('https://sgp1.digitaloceanspaces.com/listing/yumzy/app/icons/3.1.5/icomoon.eot?2d12ei');
  src:  url('https://sgp1.digitaloceanspaces.com/listing/yumzy/app/icons/3.1.5/icomoon.eot?2d12ei#iefix') format('embedded-opentype'),
    url('https://sgp1.digitaloceanspaces.com/listing/yumzy/app/icons/3.1.5/icomoon.ttf?2d12ei') format('truetype'),
    url('https://sgp1.digitaloceanspaces.com/listing/yumzy/app/icons/3.1.5/icomoon.woff?2d12ei') format('woff'),
    url('https://sgp1.digitaloceanspaces.com/listing/yumzy/app/icons/3.1.5/icomoon.svg?2d12ei#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bag:before {
  content: "\e92b";
}
.icon-call:before {
  content: "\e92c";
}
.icon-bike:before {
  content: "\e900";
}
.icon-gift:before {
  content: "\e901";
}
.icon-store:before {
  content: "\e902";
}
.icon-tag:before {
  content: "\e903";
}
.icon-favorite:before {
  content: "\e925";
}
.icon-menu_icon:before {
  content: "\e926";
}
.icon-home:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e90f";
}
.icon-minus:before {
  content: "\e919";
}
.icon-menu_cart:before {
  content: "\e91d";
}
.icon-person:before {
  content: "\e922";
}
.icon-logout:before {
  content: "\e904";
}
.icon-arrow-long-back:before {
  content: "\e905";
}
.icon-arrow-long-forward:before {
  content: "\e910";
}
.icon-arrow-back:before {
  content: "\e906";
}
.icon-arrow-forward:before {
  content: "\e918";
}
.icon-arrow-angle-back:before {
  content: "\e91f";
}
.icon-arrow-angle-forward:before {
  content: "\e921";
}
.icon-plus:before {
  content: "\e920";
}
.icon-arrow-dropdown:before {
  content: "\e907";
}
.icon-arrow-drop-up:before {
  content: "\e908";
}
.icon-offer:before {
  content: "\e924";
}
.icon-cash:before {
  content: "\e909";
}
.icon-check-circle:before {
  content: "\e90a";
}
.icon-check-square:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-content-copy:before {
  content: "\e90d";
}
.icon-credit-card:before {
  content: "\e90e";
}
.icon-globe:before {
  content: "\e911";
}
.icon-heart:before {
  content: "\e912";
}
.icon-history:before {
  content: "\e913";
}
.icon-bhim:before {
  content: "\e914";
}
.icon-info:before {
  content: "\e915";
}
.icon-location:before {
  content: "\e916";
}
.icon-location-on:before {
  content: "\e917";
}
.icon-map-pin:before {
  content: "\e91a";
}
.icon-edit:before {
  content: "\e91b";
}
.icon-food:before {
  content: "\e91c";
}
.icon-menu:before {
  content: "\e91e";
}
.icon-share:before {
  content: "\e927";
}
.icon-star:before {
  content: "\e928";
}
.icon-suitcase:before {
  content: "\e929";
}
.icon-wallet:before {
  content: "\e92a";
}
